<template>
  <div>
    <div class="bjffff"></div>
    <div class="btBox">
      <div class="cont">
        <div class="logoimg">
          <img src="../../assets/img/logo.png" @click="jump" alt="" srcset="" />
          <div class="shu"></div>
          <div class="name">登录</div>
        </div>
      </div>
    </div>
    <div class="dlBox">
      <div class="headBox">
        <div
          class="box"
          :class="typeOn == index ? 'on' : ''"
          v-for="(item, index) in typeList"
          @click="typeClick(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="content" v-if="typeOn == 0">
        <div class="title">川田社区登录</div>
        <div class="cont">
          <div class="inp">
            <a-input placeholder="请输入手机号" v-model="mobile" />
          </div>
          <div class="inp">
            <a-input-password placeholder="请输入密码" v-model="password" />
          </div>
        </div>
        <div class="funBox">
          <div class="jzBox" @click="zhShowJs">
            <div class="kuang">
              <div v-if="zhShow" class="iconfont icon-right"></div>
            </div>
            <div class="name">记住账号</div>
          </div>
          <div class="wjBox">
            <div class="name" @click="mimaClick">忘记密码?</div>
          </div>
        </div>
        <div class="serveBox" @click="saveClick">登录</div>
      </div>
      <div class="content" v-if="typeOn == 1">
        <div class="title">川田社区登录</div>
        <div class="cont">
          <div class="inp">
            <a-input placeholder="请输入手机号" v-model="mobile" />
          </div>
          <div class="inp">
            <a-input placeholder="请获取验证码" v-model="yanzheng" />
            <div class="yzmBox" v-if="!yzmShow" @click="yzmClick">
              获取验证码
            </div>
            <div class="yzmBox anxia" v-if="yzmShow">{{ time }}</div>
          </div>
        </div>
        <div class="funBox">
          <div class="jzBox" @click="zhShowJs">
            <div class="kuang">
              <div v-if="zhShow" class="iconfont icon-right"></div>
            </div>
            <div class="name">记住账号</div>
          </div>
          <!-- <div class="wjBox">
            <div class="name" @click="mimaClick">输入密码?</div>
          </div> -->
        </div>
        <div class="serveBox" @click="dxClick">登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Form, Input, Button, Upload, Icon } from "ant-design-vue";
export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-input-password": Input.Password,
    "a-button": Button,
    "a-textarea": Input.TextArea,
    "a-upload": Upload,
    "a-icon": Icon,
  },
  name: "",
  data() {
    return {
      typeOn: 0,
      typeList: ["普通登录", "短信快捷登录"],
      zhShow: false,
      mobile: "",
      password: "",
      time: 60,
      yzmShow: false,
      yanzheng: "",
    };
  },
  created() {
    console.log(window.localStorage.getItem("chuantianmobile"));
    if (window.localStorage.getItem("chuantianmobile")) {
      this.mobile = window.localStorage.getItem("chuantianmobile");
    }
    if (window.localStorage.getItem("chuantianpassword")) {
      this.password = window.localStorage.getItem("chuantianpassword");
    }
  },
  methods: {
    jump() {
      this.$router.push({
        path: "/index",
      });
    },
    yzmClick() {
      if (!this.mobile) {
        this.$message.error("请先输入手机号");
        return;
      }
      this.yzmShow = true;
      this.timeYs = setInterval(() => {
        if (this.time == 0) {
          this.yzmShow = false;
          this.time = 60;
          clearInterval(this.timeYs);
        } else {
          this.time--;
        }
      }, 1000);
      this.$post("/common/sms_send", {
        mobile: this.mobile,
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          this.$message.success("发送成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    saveClick() {
      if (!this.mobile) {
        this.$message.error("请输入手机账号");
        return;
      }
      if (!this.password) {
        this.$message.error("请输入密码");
        return;
      }
      this.$post("/web/login", {
        mobile: this.mobile,
        password: this.password,
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          if (this.zhShow) {
            window.localStorage.setItem("chuantianmobile", this.mobile);
            window.localStorage.setItem("chuantianpassword", this.password);
          }
          window.localStorage.setItem("token1", res.data.data);
          window.localStorage.setItem(
            "member",
            JSON.stringify(res.data.member)
          );
          this.$message.success("登录成功");
          setTimeout(() => {
            // window.open("/chuantian/index");
            this.$router.replace({
              path: "/index",
              query: {},
            });
          }, 500);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    dxClick() {
      if (!this.mobile) {
        this.$message.error("请输入手机账号");
        return;
      }
      if (!this.yanzheng) {
        this.$message.error("请输入验证码");
        return;
      }
      this.$post("/web/login_sms", {
        mobile: this.mobile,
        code: this.yanzheng,
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          if (this.zhShow) {
            window.localStorage.setItem("chuantianmobile", this.mobile);
          }
          window.localStorage.setItem("token1", res.data.data);
          window.localStorage.setItem(
            "member",
            JSON.stringify(res.data.member)
          );
          this.$message.success("登录成功");
          setTimeout(() => {
            // window.open("/chuantian/index");
            this.$router.replace({
              path: "/index",
              query: {},
            });
          }, 500);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    typeClick(i) {
      this.typeOn = i;
    },
    zhShowJs() {
      this.zhShow = !this.zhShow;
    },
    mimaClick() {
      this.$router.push({
        path: "/mima",
        query: {},
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.btBox {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0 2px 2px 1px whitesmoke;
  font-family: PingFangSC-Regular, sans-serif;
  .cont {
    width: 1440px;
    margin: auto;
    display: flex;
    height: 55px;
    align-items: center;
    justify-content: space-between;

    .logoimg {
      height: 30px;
      display: flex;
      align-items: center;

      img {
        height: 100%;
        width: auto;
      }

      .shu {
        width: 1px;
        height: 15px;
        margin: 0 10px;
        background-color: #999;
      }

      .name {
        font-size: 18px;
        font-weight: 700;
        color: #333;
      }
    }
  }
}
.dlBox {
  width: 420px;
  border: 1px solid #e0e0e0;
  margin: auto;
  margin-top: 200px;
  border-radius: 5px;
  overflow: hidden;
  .headBox {
    height: 50px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    .box {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: #ebebeb;
      cursor: pointer;
      color: #9c9c9c;
    }
    .on {
      color: #000;
      background-color: #fff;
    }
  }
  .content {
    padding: 25px;
    .title {
      font-weight: 600;
      font-size: 18px;
      text-align: center;
    }
    .cont {
      margin-top: 10px;
      .inp {
        margin-top: 20px;
        height: 40px;
        position: relative;
        .yzmBox {
          position: absolute;
          top: 1px;
          right: 1px;
          width: 80px;
          height: calc(100% - 2px);
          background-color: #ce0808;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 12px;
        }
        .anxia {
          background-color: #999;
        }
        .ant-input {
          height: 100% !important;
        }
        .ant-input-affix-wrapper {
          height: 100%;
        }
        /deep/input {
          height: 100%;
        }
      }
    }
    .funBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      .jzBox {
        display: flex;
        align-items: center;
        cursor: pointer;
        .kuang {
          width: 14px;
          height: 14px;
          border-radius: 2px;
          border: 1px solid #999;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          .iconfont {
            font-size: 12px;
            color: #e20808;
            font-weight: 600;
          }
        }
        .name {
          color: #9c9c9c;
          font-size: 12px;
        }
      }
      .wjBox {
        cursor: pointer;
        .name {
          font-size: 12px;
          color: #9c9c9c;
        }
      }
    }
    .serveBox {
      width: 100%;
      height: 40px;
      background-color: #e20808;
      color: #fff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-top: 20px;
    }
  }
}
</style>
